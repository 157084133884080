import React, { memo, useRef, useEffect, useState } from "react";
import "./video.scss";
import MediaHeader from "../media-header/media-header";
import videojs from "video.js";
import * as moment from "moment";
const debounce = require("lodash.debounce");

const Video = ({
  videoLink,
  eventName,
  organizer,
  toggleChat,
  liveChat,
  viewCount,
}) => {
  const [status, setStatus] = useState({
    lastMovement: "",
    focused: false,
    header: false,
    lastTouched: "",
  });

  const videoRef = useRef(null);

  // const playVideo = (play) => {
  //   const player = videojs("hls-example");
  //   if (videoRef.current) {
  //     if (play) {
  //       player.play();
  //     } else {
  //       player.pause();
  //     }
  //   }
  // };

  // const goLive = () => {
  //   videoRef.current.currentTime = videoRef.current.duration - 2;
  //   videoRef.current.play();
  // };

  // const onMute = (mute) => {
  //   videoRef.current.muted = mute;
  // };

  useEffect(() => {
    var player = videojs("hls-example");
    player.play();
    // document
    //   .querySelector(".vjs-play-control")
    //   .addEventListener("click", () => {
    //     document.querySelector(".vjs-seek-to-live-control").click();
    //   });
  }, []);

  const handleMouseActivity = (time) => {
    setStatus((prev) => ({
      ...prev,
      lastMovement: time,
      focused: false,
    }));
  };

  const onFocus = () => {
    setStatus((prev) => ({
      ...prev,
      focused: true,
    }));
  };

  const isFocused = (time) => {
    if (!status.focused && time !== status.lastMovement) {
      handleMouseActivity(time);
      setTimeout(() => {
        if (!status.focused) {
          onFocus();
        }
      }, 5000);
    } else {
      handleMouseActivity(time);
    }
  };

  const showHeader = (time) => {
    setStatus((prev) => ({
      ...prev,
      header: true,
      lastTouched: time,
    }));
    hideHeader(time);
  };

  const hideHeader = (time) => {
    setTimeout(() => {
      if (moment(time, "HH:mm:ss").diff(moment(), "seconds") === -3) {
        setStatus((prev) => ({
          ...prev,
          header: false,
        }));
      }
    }, 3000);
  };

  return (
    <div
      className={`container ${liveChat ? "active-live-chat" : ""}  ${
        status.focused ? "focused" : ""
      } ${status.header ? "active-header" : ""}`}
      onMouseMove={() => {
        const time = moment().format("HH:mm:ss");
        isFocused(time);
      }}
      onTouchStart={debounce(() => {
        const time = moment().format("HH:mm:ss");
        showHeader(time);
      }, 1000)}
    >
      <MediaHeader
        toggleChat={toggleChat}
        eventName={eventName}
        organizer={organizer}
        viewCount={viewCount}
      ></MediaHeader>
      <div className="player">
        <video
          ref={videoRef}
          id="hls-example"
          className="video-js vjs-default-skin vid"
          width="100%"
          height="100%"
          controls
          autoPlay="autoplay"
        >
          <source type="application/x-mpegURL" src={videoLink} />
        </video>
      </div>

      {/* <Controls
        toggleFullScreen={toggleFullScreen}
        onPlay={playVideo}
        goLive={goLive}
        onMute={onMute}
      ></Controls> */}
    </div>
  );
};

export default memo(Video);
