import React, { memo } from "react";
import "./media-header.scss";
import NumericLabel from "react-pretty-numbers";

const MediaHeader = ({ eventName, organizer, toggleChat, viewCount }) => {
  const viewCountProps = {
    justification: "L",
    percentage: false,
    precision: 0,
    commafy: false,
    shortFormat: true,
    title: true,
    cssClass: ["viewers"],
  };

  return (
    <div className="media-header">
      <div className="background"></div>
      <div className="content">
        <div className="header">
          <div className="title">{eventName}</div>
          <div className="description">
            <div className="logo"></div>
            <div className="text">{organizer}</div>
          </div>
          <div className="status">
            <div className="live">Live</div>
            <div className="viewers">
              <i className="icon-user"></i>
              <NumericLabel params={viewCountProps}>{viewCount}</NumericLabel>
            </div>
          </div>
        </div>
        <div className="action">
          {/* <div>
            <i className="icon-share"></i>
          </div> */}
          <div onClick={toggleChat}>
            <i className="icon-message"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(MediaHeader);
