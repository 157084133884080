export const environment = {
  production: false,
  URL: "https://dev.streaming.pouchnation.com",
  API_V3: "https://dev.gw.pouchnation.com",
  GOOGLE_TAG_MANAGER_ID: "G-5PEMF22G9D",
  STREAM_CHAT: {
    key: "3kbu5a66xxjj",
    secret: "54fdzenqnnwdwmdjucn3pwgdsvv9wn2zkbd6q2mxyvgnhr7w3z4dbbku46gck3fh",
  },
  FIREBASE: {
    apiKey: "AIzaSyC6peWekttDuVlN_3cvtNL1t8gUklYFR2Q",
    authDomain: "pouchstreaming.firebaseapp.com",
    databaseURL: "https://pouchstreaming.firebaseio.com",
    projectId: "pouchstreaming",
    storageBucket: "pouchstreaming.appspot.com",
    messagingSenderId: "564515744020",
    appId: "1:564515744020:web:d0ab9b169275f8dd273a01",
    measurementId: "G-KRRV0VZQT7",
  },
};
