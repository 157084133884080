import React, { memo, useEffect, useState } from "react";
import "./live-chat.scss";
import { environment } from "../../../environments/environment";
import { Chat, Channel, Window } from "stream-chat-react";
import {
  MessageList,
  MessageInput,
  MessageLivestream,
} from "stream-chat-react";
import { MessageInputSmall, Thread } from "stream-chat-react";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import * as moment from "moment";
const chatClient = new StreamChat(environment.STREAM_CHAT.key);

const LiveChat = ({ eventName, liveChatHeight }) => {
  const [status, setStatus] = useState({
    loading: true,
    channel: null,
    initializing: true,
    setUpUser: true,
  });

  const [user, setUser] = useState({
    id: "",
    name: "",
    avatar: "",
  });

  const handleChangeName = (e) => {
    const { value } = e.target;
    setUser((prev) => ({
      ...prev,
      name: value,
    }));
  };

  const submitChatName = () => {
    const u = {
      name: user.name,
      id: `${moment().format("MMDDYYYYHHmmssz")}${user.name}`,
      avatar: "",
    };

    setUser((prev) => ({
      ...prev,
      ...u,
    }));
    localStorage.setItem("user", JSON.stringify(u));
    setUpClient(u);
  };

  const setUpClient = (u) => {
    chatClient.disconnect();
    chatClient.setGuestUser({ ...u }).then(() => {
      setStatus((prev) => ({
        ...prev,
        initializing: false,
        channel: chatClient.channel("livestream", "razorback", {
          image: "",
          name: "Pouchnation",
        }),
        loading: false,
      }));
    });
  };

  const handleSetUser = (u) => {
    setStatus((prev) => ({
      ...prev,
      initializing: false,
    }));
    setUser((prev) => ({
      ...prev,
      ...u,
    }));
  };

  const initializeUser = () => {
    const initializing = status.initializing;
    if (initializing) {
      setStatus((prev) => ({
        ...prev,
        initializing: false,
      }));
      const userLocal = localStorage.getItem("user");

      if (userLocal) {
        const u = JSON.parse(userLocal);
        handleSetUser(u);
        setUpClient(u);
      }
    }
  };

  useEffect(() => {
    initializeUser();
  });

  return status.initializing ? (
    <div>Loading...</div>
  ) : !user.id ? (
    <div
      className="live-chat greetings-chat"
      style={{ height: liveChatHeight }}
    >
      <div>
        <div className="welcome-header">
          <div>Welcome to</div>
          <div>{eventName}</div>
          <div className="description">Start chatting with everyone!</div>
        </div>
        <div className="form">
          <div>
            <input
              value={user.name}
              onChange={handleChangeName}
              placeholder="Enter your name to join the chat"
            ></input>
          </div>
          <div>
            <button
              className="join-chat"
              onClick={submitChatName}
              disabled={!user.name}
            >
              Join Chat
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="live-chat" style={{ height: liveChatHeight }}>
      {!status.loading && (
        <Chat client={chatClient} theme={"livestream light"}>
          <Channel channel={status.channel} Message={MessageLivestream}>
            <Window hideOnThread>
              <MessageList />
              <MessageInput Input={MessageInputSmall} focus />
            </Window>
            <Thread fullWidth />
          </Channel>
        </Chat>
      )}
    </div>
  );
};

export default memo(LiveChat);
