import React from "react";
import styles from "./post-stream.module.scss";
import { motion } from "framer-motion";
import { pageTransition, pageVariants, pageStyle } from "../animation";
import { razorback as event } from "../../../data/event";

const PostStream = () => {
  return (
    <motion.div
      key="poststream"
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className={styles.container}>
        <div className={styles.eventWrapper}>
          <div className={styles.eventInfo}>
            <p className={styles.streamState}>Thank you for watching!</p>

            <p className={styles.eventDateTime}>
              {event.date} &middot; {event.time}
            </p>
            <p
              className={styles.officialHashtag}
              style={{ color: event.officialColor }}
            >
              {event.officialHashtag}
            </p>
            <h1 className={styles.eventName}>{event.eventName}</h1>
            <p className={styles.organizer}>by {event.organizer}</p>
          </div>
          <div className={styles.shareBoxWrapper}></div>
        </div>
      </div>
    </motion.div>
  );
};

export default PostStream;
