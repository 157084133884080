import React, { useState, useEffect } from "react";
// import ShareBox from "../../../components/social/share-box/share-box";
import styles from "./pre-stream.module.scss";
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import { pageStyle, pageVariants, pageTransition } from "../animation";
import { motion } from "framer-motion";
import { razorback as event } from "../../../data/event";

const PreStream = () => {
  const history = useHistory();
  const [remainingTime, setRemainingTime] = useState("...");

  useEffect(() => {
    var startDuration = moment.duration(
      moment.utc(event.eventStartTimestamp).diff(moment.utc(moment())),
      "milliseconds"
    );

    var endDuration = moment.duration(
      moment.utc(event.eventEndTimestamp).diff(moment.utc(moment())),
      "milliseconds"
    );

    if (endDuration <= 0) {
      history.push("/" + event.slug + "/done");
    } else {
      if (event.testMode) {
        startDuration = moment.duration(10, "seconds");
      }

      var oneSecond = 1000;

      setInterval(function () {
        startDuration = moment.duration(
          startDuration - oneSecond,
          "milliseconds"
        );
        setRemainingTime(startDuration.humanize(true));
        if (startDuration <= 0) {
          history.push("/" + event.slug + "/live");
        }
      }, oneSecond);
    }
  });

  return (
    <motion.div
      key="prestream"
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div className={styles.container}>
        <div className={styles.eventWrapper}>
          <div className={styles.eventInfo}>
            <p className={styles.streamState}>Live {remainingTime}</p>

            <p className={styles.eventDateTime}>
              {event.date} &middot; {event.time}
            </p>
            <p
              className={styles.officialHashtag}
              style={{ color: event.officialColor }}
            >
              {event.officialHashtag}
            </p>
            <h1 className={styles.eventName}>{event.eventName}</h1>
            <p className={styles.organizer}>by {event.organizer}</p>
          </div>
          {/* <div className={styles.shareBoxWrapper}>
            <ShareBox shareLink={event.shareLink}></ShareBox>
          </div> */}
        </div>
      </div>
    </motion.div>
  );
};

export default PreStream;
