import React, { Fragment } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { LiveStreaming, PreStream, PostStream } from "./pages";
import { AnimatePresence } from "framer-motion";
import styles from "./streaming/default.module.scss";

const AppPages = () => {
  const location = useLocation();

  return (
    <Fragment>
      <div className={styles.container}>
        <AnimatePresence>
          <Switch location={location} key={location.pathname}>
            <Route component={PreStream} path="/razorback/waiting" />
            <Route component={LiveStreaming} path="/razorback/live" />
            <Route component={PostStream} path="/razorback/done" />
            <Redirect to="/razorback/waiting"></Redirect>
          </Switch>
        </AnimatePresence>

        <div className={styles.background}>
          <div className={styles.blur}></div>
        </div>
        <div className={styles.backgroundMask}></div>
      </div>
    </Fragment>
  );
};

export default AppPages;
