import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import AppPages from "./pages";

function App() {
  return (
    <Router>
      {/* <Switch> */}
      <Route component={AppPages} path={"/*"} />
      {/* </Switch> */}
    </Router>
  );
}

export default App;
