import React, { useState, useCallback, useEffect } from "react";
import "./live-streaming.module.scss";
import { Video } from "../../../components/media";
import { LiveChat } from "../../../components/chat";
import { environment } from "../../../environments/environment";
import { pageStyle, pageVariants, pageTransition } from "../animation";
import { motion } from "framer-motion";
import { razorback as event } from "../../../data/event";
import * as firebase from "firebase";
import * as moment from "moment";
import { ReactElementResize } from "react-element-resize";

const LiveStreaming = () => {
  const [status, setStatus] = useState({
    viewCount: 1,
    liveChat: false,
    fullScreen: false,
    liveChatHeight: "100%",
  });

  const onToggleChat = useCallback(() => {
    setStatus((prev) => ({
      ...prev,
      liveChat: !status.liveChat,
    }));
  }, [status]);

  useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.FIREBASE);
      const db = firebase.database();
      firebase
        .auth()
        .signInAnonymously()
        .then((value) => {
          const user = value.user;

          const userRef = db.ref("viewers/" + user.uid);
          const connectedRef = firebase.database().ref(".info/connected");
          connectedRef.on("value", function (snap) {
            if (snap.val() === true) {
              userRef.set({
                viewing: event.slug,
                timestamp: moment.utc().format(),
              });
            }
          });
          userRef.onDisconnect().remove();

          const viewersRef = db.ref("viewers");
          const viewCountRef = db.ref("viewCount");
          viewersRef.once("value").then(function (snapshot) {
            const viewCount = snapshot.numChildren();
            viewCountRef.set(viewCount);
          });
        })
        .catch(function (error) {});

      const viewCountRef = db.ref("viewCount");
      viewCountRef.on("value", (data) => {
        setStatus((prev) => ({
          ...prev,
          viewCount: data.val(),
        }));
      });
    }
  });

  const toggleFullScreen = (isFullScreen) => {
    setStatus((prev) => ({
      ...prev,
      fullScreen: isFullScreen,
    }));
  };

  const onResize = ({ width, height }) => {
    setStatus((prev) => ({
      ...prev,
      liveChatHeight: width < 1024 ? `calc(100% - ${height}px)` : "100%",
    }));
  };

  return (
    <motion.div
      key="prestream"
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div
        className={`streaming ${status.fullScreen ? "fullscreen" : ""} ${
          status.liveChat ? "active-live-chat" : ""
        }`}
      >
        <ReactElementResize onResize={onResize}>
          {(data) => (
            <div className="stream-player">
              <Video
                {...event}
                viewCount={status.viewCount}
                liveChat={status.liveChat}
                toggleChat={onToggleChat}
                toggleFullScreen={toggleFullScreen}
                fullScreen={status.fullScreen}
              ></Video>
            </div>
          )}
        </ReactElementResize>

        {status.liveChat && (
          <div style={{ height: status.liveChatHeight }}>
            <LiveChat
              eventName={event.eventName}
              liveChatHeight={status.liveChatHeight}
            ></LiveChat>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default LiveStreaming;
